import React from 'react'

function PaymentHistory() {
  return (
    <div>
      <h2>Payment History</h2>
    </div>
  )
}

export default PaymentHistory

const Sections = [
    {
        _id: 1,
        section: 'A',
    },
    {
        _id: 2,
        section: 'B',
    },
    {
        _id: 3,
        section: 'C',
    },
    {
        _id: 4,
        section: 'D',
    },
    {
        _id: 5,
        section: 'E',
    },
    {
        _id: 6,
        section: 'F',
    },
    {
        _id: 7,
        section: 'G',
    },

    {
        _id: 8,
        section: 'H',
    },
    {
        _id: 9,
        section: "I",
    },
    {
        _id: 10,
        section: 'J',
    },


]

export default Sections
import React from 'react'
import Sidebar from '../components/Sidebar'

function ParentDashboard() {
  return (
    <div>
        <h1>Hello! This is Parent Dashboard....</h1>
        <Sidebar />
    </div>
  )
}

export default ParentDashboard

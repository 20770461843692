import React from "react";
import "../Css/Home.css";

import Dashboard from "../screen/Dashboard";

export default function Home() {
  return (
    <div className="home">
      <Dashboard></Dashboard>
    </div>
  );
}
